<template>
  <!-- start: notification-dropdown-trigger -->
  <div ref="notificationContainer">
    <div
      @click="open = !open"
      class="flex justify-center items-center px-4 border-l cursor-pointer hover:bg-gray-300 w-55px h-55px"
    >
      <div class="relative rounded-lg border border-gray-700">
        <div
          class="absolute w-2 h-2 rounded-full bg-red-notification"
          style="top: -2px; right: -2.5px"
        ></div>
        <span class="p-2 text-xs text-red-notification">{{ unseenCount }}</span>
      </div>
    </div>
    <div
      v-if="open"
      ref="backdrop"
      class="fixed right-0 w-full"
      style="top:55px;height: calc(100vh - 55px); width: 100vw; background-color: rgb(3 3 3 / 32%)"
    >
      <div
        class="fixed right-0 bg-white shadow-md notificationDropdown"
        style="height: calc(100vh - 55px)"
      >
        <div class="flex justify-between items-center">
          <h1 class="p-5 font-semibold section-title text-28px">
            Notification
          </h1>
          <section class="flex items-center">
            <!-- <img class="mr-1 w-8 h-8" src="@/assets/img/new.png" /> -->
            <router-link
              :to="{ name: 'NotificationIndex', params: {} }"
              :class="`text-blue-600 mr-5`"
              target="_blank"
            >
              View Details
            </router-link>
          </section>
        </div>
        <div class="pt-4">
          <div class="flex px-5 mb-2">
            <div
              @click="categoryFilter = ''"
              :class="
                categoryFilter === ''
                  ? 'border-b-2 border-black'
                  : 'text-gray-400'
              "
              class="flex-1 pb-2 text-center cursor-pointer"
            >
              All
            </div>
            <div
              @click="categoryFilter = 'V'"
              :class="
                categoryFilter === 'V'
                  ? 'border-b-2 border-black'
                  : 'text-gray-400'
              "
              class="flex-1 pb-2 text-center cursor-pointer"
            >
              Vehicle
            </div>
            <div
              @click="categoryFilter = 'A'"
              :class="
                categoryFilter === 'A'
                  ? 'border-b-2 border-black'
                  : 'text-gray-400'
              "
              class="flex-1 pb-2 text-center cursor-pointer"
            >
              Area
            </div>
            <div
              @click="categoryFilter = 'W'"
              :class="
                categoryFilter === 'W'
                  ? 'border-b-2 border-black'
                  : 'text-gray-400'
              "
              class="flex-1 pb-2 text-center cursor-pointer"
            >
              eWallet
            </div>
            <div
              @click="categoryFilter = 'R'"
              :class="
                categoryFilter === 'R'
                  ? 'border-b-2 border-black'
                  : 'text-gray-400'
              "
              class="flex-1 pb-2 text-center cursor-pointer"
            >
              Report
            </div>
          </div>
          <div
            v-if="
              stickyDateIndex >= 0 &&
                groupedByDateNotificationShowChildrenCount[activeStickyDate] * 1
            "
            class="py-2 px-5 font-bold text-gray-500 bg-gray-200"
          >
            {{ toDisplayDate(activeStickyDate) }}
          </div>

          <div
            ref="notificationListContainer"
            @scroll="notificationListScrolling"
            class="overflow-y-auto"
            style="height: calc(100vh - 55px - 82px - 60px - 40px)"
          >
            <template v-for="(date, index) in sortedNotificationGroupDates">
              <NotificationGroup
                :ref="'sortedNotificationGroupDates' + index"
                :date="date"
                :notifications="groupedByDateNotificationLookUp[date]"
                :category-filter="categoryFilter"
                :notification-list-look-up="notificationListLookUp"
                :grouped-notification-look-up="groupedNotificationLookUp"
                :show-header="index !== stickyDateIndex"
                :key="'notif' + index"
                @children-count-change="childrenCountChange(date, $event)"
              />
            </template>
            <div class="py-2 text-center">
              <button
                v-if="hasLoadMore"
                @click="getNotifications"
                class="py-1 px-6 bg-gray-200 rounded-full shadow-none"
                v-observe-visibility="{
                  callback: notificationListObserver,
                  once: false,
                }"
              >
                Load More <i class="ml-1 fas fa-arrow-down"></i>
              </button>
            </div>
            <loading :active.sync="isLoading" :is-full-page="false" />
          </div>
        </div>
      </div>
    </div>
    <audio ref="audio" src="@/assets/sound/notification_tune.mp3"></audio>
  </div>
  <!-- end: notification-dropdown-trigger -->
</template>
<script>
import Vue from 'vue'
import apiConfig from '@/config/api/config'
import Helper from './notification-dropdown-trigger-components/helper'
import NotificationGroup from './notification-dropdown-trigger-components/NotifcationGroup.vue'
export default {
  components: {
    NotificationGroup,
  },
  created() {
    this.connectToWebsocket()
  },
  mounted() {
    document.addEventListener('click', this.autoCloseEventListener)
  },
  data() {
    return {
      unseenCount: 0,
      unseenNotificationIdList: [],
      open: false,
      notificationListLookUp: {},
      groupedByDateNotificationLookUp: {},
      groupedNotificationLookUp: {}, // key is dayGroupKey + subcategory + extra_data
      groupedByDateNotificationShowChildrenCount: {}, // determine the children that is not filtered
      sortedNotificationGroupDates: [],
      categoryFilter: '',
      notificationConnection: null,
      loadOnOpen: true,
      isLoading: false,
      stickyDateIndex: -1,
      totalLoadedNotification: {
        all: 0,
        V: 0,
        A: 0,
        W: 0,
        R: 0,
      },
      totalNotificationSummary: {
        all: 0,
        V: 0,
        A: 0,
        W: 0,
        R: 0,
      },
      resultLimit: 10,

      // visibility observer for notification list
      vis: {
        previousY: 0,
        previousRatio: 0,
      },
      // is scrolling down
      visScrollDown: false,
    }
  },
  methods: {
    notificationListScrolling(e) {
      e = e || window.event
      if (e.preventDefault) e.preventDefault()
      e.returnValue = false
      for (let x = 0; x < this.sortedNotificationGroupDates.length; x++) {
        const notificationGroup = this.$refs[
          'sortedNotificationGroupDates' + x
        ][0].$el
        const isInViewed = Helper.checkInView(
          this.$refs.notificationListContainer,
          notificationGroup,
          true
        )
        if (isInViewed) {
          this.stickyDateIndex = x
          break
        }
      }
    },
    childrenCountChange(date, newCount) {
      Vue.set(this.groupedByDateNotificationShowChildrenCount, date, newCount)
    },

    notificationListObserverThreshold(steps) {
      const thresholdArray = Array(steps + 1)
        .fill(0)
        .map((_, index) => index / steps || 0)
      return thresholdArray
    },

    // intersection observer args
    // https://codepen.io/elroy_tsai/pen/MWWXWVZ
    notificationListObserver(isVisible, entry) {
      let previousY = this.vis.previousY
      let previousRatio = this.vis.previousRatio

      if (entry.intersectionRatio <= 0) return

      const currentY = entry.boundingClientRect.y
      const { currentRatio, isIntersecting } = entry

      // Scrolling down/up (due to comparing with only one entry, only scrolling up enter is triggered)
      if (currentY < previousY) {
        if (currentRatio > previousRatio && isIntersecting) {
          console.log('Scrolling down enter')
        } else {
          console.log('Scrolling down leave')
        }
      } else if (currentY > previousY && isIntersecting) {
        if (currentRatio < previousRatio) {
          console.log('Scrolling up leave')
        } else {
          // console.log('Scrolling up enter')
          this.getNotifications()
        }
      }

      previousY = currentY
      previousRatio = currentRatio
    },

    getNotifications() {
      this.isLoading = true
      let offset = null
      this.loadOnOpen = false
      let categoryFilterParam = ''
      if (this.categoryFilter !== '') {
        categoryFilterParam = '&category=' + this.categoryFilter
        offset = this.totalLoadedNotification[this.categoryFilter]
      } else {
        offset = this.totalLoadedNotification['all']
      }
      this.$http(
        '/dashboard/notifications/?limit=' +
          this.resultLimit +
          '&offset=' +
          offset +
          categoryFilterParam
      )
        .then((result) => {
          result['data']['data'].forEach((notification) => {
            console.log('notification-getNotifications')
            this.addNotification(notification)
            this.seenNotification(notification)
          })
          this.sortedNotificationGroupDates = this.sortedNotificationGroupDates.sort(
            function(a, b) {
              return new Date(b) - new Date(a)
            }
          )
          this.isLoading = false
          if (
            this.stickyDateIndex === -1 &&
            this.result['data']['data'].length
          ) {
            this.stickyDateIndex = 0
          }
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    addNotification(notification, prepend = false) {
      // console.log('notification-addedData', notification)
      // prepend is used for new notifications
      Vue.set(this.notificationListLookUp, notification['id'], notification)
      const createdAt = new Date(notification['created_at'])
      const dayGroupKey =
        createdAt.getMonth() +
        1 +
        '/' +
        createdAt.getDate() +
        '/' +
        createdAt.getFullYear()
      const sameNotificationKey =
        dayGroupKey +
        '_' +
        notification['subcategory'] +
        '_' +
        Helper.generateExtraDataKey(notification['extra_data'])
      if (
        typeof this.groupedNotificationLookUp[sameNotificationKey] ===
        'undefined'
      ) {
        Vue.set(this.groupedNotificationLookUp, sameNotificationKey, [])
      }
      if (prepend) {
        this.groupedNotificationLookUp[sameNotificationKey].unshift(
          notification['id']
        )
      } else {
        this.groupedNotificationLookUp[sameNotificationKey].push(
          notification['id']
        )
      }
      if (
        typeof this.groupedByDateNotificationLookUp[dayGroupKey] === 'undefined'
      ) {
        Vue.set(this.groupedByDateNotificationLookUp, dayGroupKey, [])
        this.sortedNotificationGroupDates.push(dayGroupKey)
      }
      const groupedByDateNotificationLookUpKey = this.groupedByDateNotificationLookUp[
        dayGroupKey
      ].indexOf(sameNotificationKey)
      if (groupedByDateNotificationLookUpKey === -1) {
        if (prepend) {
          this.groupedByDateNotificationLookUp[dayGroupKey].unshift(
            sameNotificationKey
          )
        } else {
          this.groupedByDateNotificationLookUp[dayGroupKey].push(
            sameNotificationKey
          )
        }
      } else if (prepend) {
        this.groupedByDateNotificationLookUp[
          dayGroupKey
        ] = this.groupedByDateNotificationLookUp[dayGroupKey].sort(
          (sameNotificationKeyA, sameNotificationKeyB) => {
            const notificationAId = this.groupedNotificationLookUp[
              sameNotificationKeyA
            ][0]
            const notificationBId = this.groupedNotificationLookUp[
              sameNotificationKeyB
            ][0]
            const notificationACreatedAt = this.notificationListLookUp[
              notificationAId
            ]['created_at']
            const notificationBCreatedAt = this.notificationListLookUp[
              notificationBId
            ]['created_at']
            return (
              new Date(notificationBCreatedAt) -
              new Date(notificationACreatedAt)
            )
          }
        )
      }
      this.totalLoadedNotification['all'] += 1
      this.totalLoadedNotification[notification['category']] += 1
    },
    connectToWebsocket() {
      console.log('Connecting to Notification Websocket')
      const authToken = localStorage.getItem('token')
      if (!authToken) {
        console.error('Cannot connect to notification because not logged in')
        return false
      }
      if (typeof apiConfig.wsBase === 'undefined') {
        console.error('Websocket base url is not defined')
        return false
      }
      this.notificationConnection = new WebSocket(
        apiConfig.wsBase + 'dashboard/notifications/?token=' + authToken
      )
      let seenTimeout = null
      this.notificationConnection.onmessage = (event) => {
        const data = JSON.parse(event.data)
        console.log('top-notification', data)
        if (data['n_type'] === 'noti.new') {
          this.$refs.audio.play()
        }
        if (
          typeof data['summary'] !== 'undefined' &&
          typeof data['summary']['unseen'] !== 'undefined'
        ) {
          this.unseenCount = data['summary']['unseen'] * 1
        }
        if (typeof data['summary'] !== 'undefined') {
          this.totalNotificationSummary = {
            all: data['summary']['total'],
            V: data['summary']['vehicle'],
            A: data['summary']['area'],
            W: data['summary']['ewallet'],
            R: data['summary']['report'],
          }
        }
        if (
          typeof data['data'] !== 'undefined' &&
          typeof data['data']['id'] !== 'undefined' &&
          !this.loadOnOpen // Dont Add the new notifiation if Notification has never been open
        ) {
          console.log('notification-connectToWebsocket')
          this.addNotification(data['data'], true)
          if (!data['data']['seen']) {
            this.unseenNotificationIdList.push(data['data']['id'])
            if (this.open) {
              // seen the newly inserted notification if the Notification is Open
              clearTimeout(seenTimeout)
              seenTimeout = setTimeout(() => {
                this.seenNewNotifications()
              }, 1000)
            }
          }
        }
      }
      this.notificationConnection.onerror = (event) => {
        console.error('Error on notifcation websocket', event)
        // setTimeout(() => {
        //   // reconnect after 3000 seconds after closed/disconnected
        //   this.connectToWebsocket();
        // }, 3000);
      }
      this.notificationConnection.onclose = (event) => {
        console.error('Notification websocket closed', event)
        // setTimeout(() => {
        //   // reconnect after 3000 seconds after closed/disconnected
        //   this.connectToWebsocket();
        // }, 3000);
      }
      this.notificationConnection.onopen = () => {
        // DO NOT DO THIS NOW : ITS CAUSING NETWORK RELATED ISSUES
        // setTimeout(() => {
        //   this.notificationConnection.send(
        //     JSON.stringify({
        //       summary: true
        //     })
        //   );
        // setTimeout(() => {
        //   this.sampleReceiveNewData();
        //   setTimeout(() => {
        //     this.sampleReceiveNewData({
        //       stack_count: 6
        //     });
        //   }, 4000);
        // }, 6000);
        // }, 1000);
      }
    },
    // sampleReceiveNewData(overwriteData) {
    //   // This is for testing new notification
    //   let data = {
    //     data: {
    //       id: '3b8cf651-8126-4439-b996-4a9a14e3c22d',
    //       category: 'A',
    //       subcategory: 'LZ',
    //       message: 'Vehicle is inside slow zone',
    //       created_at: '2021-01-14 12:20:24.468765+00:00',
    //       seen: false,
    //       extra_data: {
    //         bike_id: '5ccdfb58-8167-4bd3-8385-dac24ab66832',
    //         user_id: '5ccdfb58-8167-4bd3-8385-dac24ab66832',
    //         bike_qr_code: '1210010007',
    //         slow_zone_id: '7b204bde-82e5-434b-bfbe-63d37e259kca',
    //       },
    //       stack_count: 5,
    //     },
    //     summary: {
    //       total: 1,
    //       unseen: 1,
    //       vehicle: 0,
    //       area: 1,
    //       ewallet: 0,
    //       report: 0,
    //     },
    //   }
    //   for (let field in overwriteData) {
    //     data['data'][field] = overwriteData[field]
    //   }
    //   console.log('notification-sampleReceiveNewData')
    //   this.addNotification(data['data'], true)
    //   if (!data['data']['seen']) {
    //     this.unseenNotificationIdList.push(data['data']['id'])
    //     if (this.open) {
    //       // seen the newly inserted notification if the Notification is Open
    //       setTimeout(() => {
    //         this.seenNewNotifications()
    //       }, 3000)
    //     }
    //   }
    // },
    seenNotification(notification) {
      if (!notification['seen']) {
        setTimeout(() => {
          this.notificationConnection.send(
            JSON.stringify({
              noti_id: notification['id'],
            })
          )
          Vue.set(this.notificationListLookUp[notification['id']], 'seen', true)
        }, 3000)
      }
    },
    seenNewNotifications() {
      let newUnseenNotificationList = []
      while (this.unseenNotificationIdList.length) {
        const notificationId = this.unseenNotificationIdList.shift()
        const notification = this.notificationListLookUp[notificationId]
        if (
          this.categoryFilter === '' ||
          notification['category'] === this.categoryFilter
        ) {
          this.seenNotification(notification)
        } else {
          newUnseenNotificationList.push(notificationId)
        }
      }
      this.unseenNotificationIdList = newUnseenNotificationList
    },
    autoCloseEventListener(e) {
      // trigger if mouse is clicked outside of notifcation
      const notificationContainer = this.$refs.notificationContainer
      if (
        (typeof notificationContainer !== 'undefined' &&
          notificationContainer !== e.target &&
          !notificationContainer.contains(e.target)) ||
        e.target === this.$refs.backdrop
      ) {
        this.open = false
        console.log('hit-close')
      }
    },
    toDisplayDate: Helper.toDisplayDate,
  },
  computed: {
    hasLoadMore() {
      let category = this.categoryFilter === '' ? 'all' : this.categoryFilter
      return (
        this.totalLoadedNotification[category] <
        this.totalNotificationSummary[category]
      )
    },
    activeStickyDate() {
      return this.stickyDateIndex !== -1
        ? this.sortedNotificationGroupDates[this.stickyDateIndex]
        : null
    },
  },
  watch: {
    open(open) {
      if (open) {
        if (this.loadOnOpen) {
          this.getNotifications()
        } else {
          this.sortedNotificationGroupDates = this.sortedNotificationGroupDates.sort(
            function(a, b) {
              return new Date(b) - new Date(a)
            }
          )
          this.seenNewNotifications() // seen all new notification under selected category filter
        }
      }
    },
    categoryFilter(categoryFilter) {
      if (
        categoryFilter !== '' &&
        this.totalLoadedNotification[categoryFilter] < this.resultLimit
      ) {
        this.getNotifications()
      }
      this.seenNewNotifications() // seen all new notification under selected category filter
    },
  },
  destroy() {
    document.removeEventListener('click', this.autoCloseEventListener)
  },
}
</script>
<style>
.notificationDropdown {
  width: 100%;
}

@media screen and (min-width: 640px) {
  .notificationDropdown {
    width: 640px;
  }
}
</style>
